export enum GameIds {
  GAME_HASH = 'GAME_HASH',
  H_BP = 'H_BP',
  H_OE = 'H_OE',
  H_NIUNIU = 'H_NIUNIU',
  H_LUCKY = 'H_LUCKY',
  H_BS = 'H_BS',
  C_FUTURE = 'C_FUTURE',
  C_BO = 'C_BO',
}

export enum TransactionEventEnum {
  BET = 'BET',
  ENTRY_SELL = 'ENTRY_SELL',
  ENTRY_BUY = 'ENTRY_BUY',
  ENTRY_UP = 'ENTRY_UP',
  ENTRY_DOWN = 'ENTRY_DOWN',
  // Close
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  REWARD = 'REWARD',
  CLOSE = 'CLOSE',
  TP = 'TP',
  SL = 'SL',
  BURST = 'BURST',
  REFUND = 'REFUND',
  BO_REWARD = 'BO_REWARD',
  BO_DRAW = 'BO_DRAW',
  BO_REFUND = 'BO_REFUND', // when create order failed
  H_DRAW = 'H_DRAW',
}

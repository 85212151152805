import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { ColorType } from 'src/theme/core';
import { useTheme } from '@mui/material/styles';
import type { CardProps } from '@mui/material/Card';
import { Iconify } from 'src/components/iconify';
import { usePermissions, useTranslate } from 'react-admin';
import { PermissionData } from '../../../entities/permission.entity';
import { isEmpty, keyBy } from 'lodash';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { fShortenNumber } from 'src/utils/format-number';

type Props = CardProps & {
  icon: string;
  title: string;
  total: string;
  color?: ColorType;
  tooltip?: string;
};

const ItemCard = ({ sx, icon, title, total, color = 'warning', tooltip, ...other }: Props) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: 3,
        pl: 3,
        pr: 3,
        mr: '5px',
        borderRadius: '16px',
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Stack flexDirection="row" alignItems="center">
          <Typography noWrap variant="h6">
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              title={<Typography fontSize={12}>{tooltip}</Typography>}
              placement="top"
              enterDelay={300}
            >
              <Iconify
                width={16}
                icon="eva:info-outline"
                ml="5px"
                sx={{ cursor: 'pointer', color: 'text.disabled' }}
              />
            </Tooltip>
          )}
        </Stack>
        <Typography variant="h6" mt={1}>
          {total}
        </Typography>
      </Box>
      <Iconify
        icon={
          icon === 'revenue'
            ? `heroicons-outline:currency-dollar`
            : `flowbite:chart-mixed-dollar-solid`
        }
        sx={{
          top: 24,
          right: 16,
          width: 42,
          height: 42,
          position: 'absolute',
          color: theme.vars.palette[color].main,
        }}
      />

      <Box
        sx={{
          top: -44,
          width: 160,
          zIndex: 1,
          height: 160,
          right: -104,
          opacity: 0.12,
          borderRadius: 3,
          position: 'absolute',
          transform: 'rotate(40deg)',
          background: `linear-gradient(135deg, ${theme.vars.palette[color].main} 0%, ${theme.vars.palette[color].dark} 100%)`,
        }}
      />
    </Card>
  );
};

interface IProps {
  data: any;
  days: string;
  loading: boolean;
}
export const ListCard = ({ loading, data, days }: IProps) => {
  const { permissions } = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');
  const isAdmin = !isEmpty(checkPermission['*']?.action);
  const translate = useTranslate();

  // const localUserData = localStorage?.getItem('user_data')
  //   ? JSON.parse(localStorage.getItem('user_data') ?? '')
  //   : null;
  const currency = 'USD';

  const totalProfit = data?.profits?.reduce((a: number, b: number) => a + b, 0) ?? 0;
  const totalRevenue = data?.revenues?.reduce((a: number, b: number) => a + b, 0) ?? 0;

  return (
    <Grid
      container
      sx={{
        gap: '12px',
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
        width: { xs: '100%', md: '66.67%' },
      }}
    >
      <>
        {loading ? (
          <Skeleton height="112px" width="100%" />
        ) : (
          <ItemCard
            title={translate('module.dashBoard.revenue')}
            total={`${currency} ${fShortenNumber(totalRevenue ?? 0)}`}
            color="secondary"
            icon="revenue"
            tooltip={translate('tooltips.module.dashboard.revenue')}
          />
        )}

        {loading ? (
          <Skeleton height="112px" width="100%" />
        ) : (
          <ItemCard
            title={`${translate('module.dashBoard.systemProfit')}`}
            total={`${currency} ${fShortenNumber(totalProfit ?? 0)}`}
            color={
              !!data?.data
                ? data?.data?.profits[data?.data?.profits?.length - 1] > 0
                  ? 'success'
                  : 'error'
                : 'warning'
            }
            icon="profit"
            tooltip={translate('tooltips.module.dashboard.systemProfit')}
          />
        )}
      </>
    </Grid>
  );
};

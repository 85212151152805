import { GameIds } from './game.config';

export const colors = {
  itemGreen: 'var(--palette-primary-main)',
  itemGray: '#b3b3b7',
  btnBgRed: '#ff2b49',
};

export const colorByGame: { [key: string]: string } = {
  [GameIds.C_BO]: '#00f6ff',
  [GameIds.C_FUTURE]: '#22C55E',
  [GameIds.H_BP]: '#c684ff',
  [GameIds.H_BS]: '#B71D18',
  [GameIds.H_LUCKY]: '#FF5630',
  [GameIds.H_NIUNIU]: '#FFAB00',
  [GameIds.H_OE]: '#4945ff',
};

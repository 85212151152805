import { Box, Stack, Typography } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { useEffect } from 'react';
import {
  AutocompleteInput,
  // Button,
  DateField,
  Filter,
  FunctionField,
  TextField,
  // TextInput,
  useGetMany,
  useListController,
  usePermissions,
  useReference,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { varAlpha } from '../../../../theme/styles';
import CGDatagrid from '../../components/CGDatagrid';
import { CgList } from '../../components/CgList';
import { SelectGameInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import {
  boOpenTransactionType,
  closeTransactionType,
  gameResultOptions,
  openTransactionType,
} from '../../providers/constants';
import { Resources } from '../resource';
import dayjs from 'dayjs';
import CGTextInput from '../../components/CGTextInput';
import CGFilterAgency from '../../components/CGFilterAgency';
import { PermissionData } from '../../entities/permission.entity';
import BetDetail from './BetDetail';
import { CgDateTimeInput } from '../../components/CgDateTimeInput';
import { GameIds } from 'src/configs/game.config';
import { TIME_FORMAT } from 'src/configs/common.config';
import { formatNumber } from 'src/utils/format-number';
import CopyBtn from 'src/components/button/CopyBtn';

function ListFilter(props: any) {
  const isCryptoGame = useIsCryptoGame();
  const isBoGame = props.gameId === GameIds.C_BO;
  const { filterValues, setFilters } = useListController();

  useEffect(() => {
    // set default value for filter fromTime
    if (!filterValues.fromTime || !filterValues.timeTo) {
      setFilters({
        ...filterValues,
        fromTime: filterValues.timeTo
          ? dayjs(filterValues.timeTo).subtract(1, 'month').toISOString()
          : dayjs().subtract(1, 'month').toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = { ...filterValues };
    if (isCryptoGame) {
      if (!filter.result) return;
      delete filter.result;
    } else {
      if (!filter.openType && !filter.closeType) return;
      delete filter.openType;
      delete filter.closeType;
    }
    setFilters(filter);
  });

  const t = useTranslate();

  return (
    <Filter {...props}>
      <FilterGroup alwaysOn>
        <CGFilterAgency source="agencyIds" label="module.user.agency" multiple={false} alwaysOn />
        <SelectGameInput source="games" label="module.user.games" alwaysOn />
        <CGTextInput label="module.game.searchOrderId" source="orderId" alwaysOn />

        <CGTextInput label="module.game.searchUserName" source="q" alwaysOn />
        <CGTextInput label="module.game.searchUserId" source="playerIds" value="" alwaysOn />
        {isBoGame
          ? [
              <AutocompleteInput
                label="module.game.openType"
                source="openType"
                choices={boOpenTransactionType}
                alwaysOn
              />,
            ]
          : isCryptoGame
            ? [
                <AutocompleteInput
                  label="module.game.openType"
                  source="openType"
                  choices={openTransactionType}
                  alwaysOn
                />,
                <AutocompleteInput
                  label="module.game.closeType"
                  source="closeType"
                  choices={closeTransactionType}
                  alwaysOn
                />,
              ]
            : [
                <AutocompleteInput
                  label="resource.result"
                  source="result"
                  choices={gameResultOptions}
                  alwaysOn
                />,
              ]}

        <AutocompleteInput
          label="module.game.searchOrderStatus"
          source="status"
          choices={
            isCryptoGame
              ? [
                  { id: 'Pending', name: t('module.game.status.Pending') },
                  { id: 'Filled', name: t('module.game.status.Filled') },
                  { id: 'Exit', name: t('module.game.status.Exit') },
                ]
              : [
                  { id: 'Pending', name: t('module.game.status.Pending') },
                  { id: 'Finished', name: t('module.game.status.Finished') },
                ]
          }
          alwaysOn
        />
        <CGTextInput label="module.game.betAmountFrom" source="fromAmount" alwaysOn />
        <CGTextInput label="module.game.betAmountTo" source="toAmount" alwaysOn />
        <CgDateTimeInput
          label="module.game.timeFrom"
          source="fromTime"
          alwaysOn
          sx={{ width: 230 }}
        />
        <CgDateTimeInput
          label="module.game.timeTo"
          source="toTime"
          minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
          type="to"
          alwaysOn
          sx={{ width: 230 }}
        />
      </FilterGroup>
    </Filter>
  );
}

const CRYPTO_GAME_CATEGORY_ID = '2';
const useIsCryptoGame = (_gameId?: string) => {
  const { filterValues } = useListController();
  const gameId: string = _gameId || filterValues.games;
  const { referenceRecord } = useReference({
    reference: Resources.game,
    id: gameId,
  });
  const categoryId = referenceRecord?.gameCategoryId;
  return categoryId === CRYPTO_GAME_CATEGORY_ID;
};

const GameHistory = () => {
  const refresh = useRefresh();
  const translate = useTranslate();

  const { filterValues } = useListController();
  const gameId: string = filterValues.games;
  const { data } = useGetMany(Resources.game, { ids: gameId ? [gameId] : [] });
  const categoryId = get(data, '0.gameCategoryId');

  const isCryptoGame = useIsCryptoGame();
  const isBoGame = gameId === GameIds.C_BO;

  const { permissions } = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');
  const isAdmin = !isEmpty(checkPermission['*']?.action);
  const requireAgencyFilterCondition = isAdmin
    ? !filterValues?.agencyIds || !filterValues?.games
    : !filterValues?.games;

  return (
    <Stack spacing={1} direction="column" style={{ width: '100%', marginTop: '20px' }}>
      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
        {translate('resource.gameBetHistories')}
      </Typography>
      {requireAgencyFilterCondition ? (
        <CgList
          empty={false}
          filters={<ListFilter translate={translate} gameId={gameId} />}
          actions={false}
          pagination={false}
        >
          <Box
            sx={{
              mx: 2,
              mb: 2,
              height: 100,
              borderRadius: 2,
              bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.04),
              border: (theme) => `dashed 1px ${theme.vars.palette.divider}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">
              {isAdmin
                ? translate('module.game.blankState')
                : translate('module.game.blankStateAgency')}
            </Typography>
          </Box>
        </CgList>
      ) : (
        <CgList
          empty={false}
          filters={<ListFilter translate={translate} gameId={gameId} />}
          actions={false}
        >
          <CGDatagrid key={isCryptoGame + ''} rowClick={false} bulkActionButtons={false}>
            <TextField
              source="id"
              label="resource.id"
              minWidth={isCryptoGame ? 397 : 'auto'}
              maxWidth={397}
            />
            <FunctionField
              label="module.game.user"
              textAlign="left"
              minWidth={150}
              maxWidth={150}
              render={(record) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography sx={{ flex: 1 }}>{record.user}</Typography>
                  <CopyBtn text={record.playerId ?? record.userId} showMsg={false} />
                </Box>
              )}
            />
            ,
            {isBoGame
              ? [
                  <TextField source="pair" label="module.game.pair" sortable={false} />,
                  <TextField source="currency" label="module.game.wallet" sortable={false} />,
                  <FunctionField
                    label="module.game.amount"
                    textAlign="left"
                    minWidth={150}
                    maxWidth={150}
                    render={(record) => `${formatNumber(record.amount)}`}
                  />,

                  <TextField
                    source="transactionType"
                    label="module.game.orderType"
                    minWidth={150}
                    maxWidth={150}
                    textAlign="left"
                    sortable={false}
                  />,
                  <TextField
                    source="openPrice"
                    label="module.game.openPrice"
                    minWidth={150}
                    maxWidth={150}
                    textAlign="left"
                    sortable={false}
                  />,
                  <FunctionField
                    label="module.game.exitPrice"
                    textAlign="left"
                    minWidth={150}
                    maxWidth={150}
                    render={(record) => (Number(record.exitPrice) > 0 ? record.exitPrice : '--')}
                  />,
                  <TextField
                    source="result"
                    label="module.game.result"
                    sortable={false}
                    // minWidth={120}
                    // maxWidth={120}
                  />,
                ]
              : isCryptoGame
                ? [
                    <TextField source="pair" label="module.game.pair" sortable={false} />,
                    <TextField source="currency" label="module.game.wallet" sortable={false} />,
                    <FunctionField
                      label="module.game.orderPriceAndFee"
                      textAlign="left"
                      minWidth={150}
                      maxWidth={150}
                      render={(record) => `${formatNumber(record.amount)}`}
                    />,
                    <TextField
                      source="transactionType"
                      label="module.game.orderType"
                      textAlign="left"
                      minWidth={150}
                      maxWidth={150}
                      sortable={false}
                    />,
                    <TextField
                      source="exitReason"
                      label="module.game.closeType"
                      textAlign="left"
                      minWidth={150}
                      maxWidth={150}
                      sortable={false}
                    />,
                    <TextField
                      source="amountWithoutFee"
                      label="module.game.orderPrice"
                      minWidth={150}
                      maxWidth={150}
                    />,
                    <TextField
                      source="fee"
                      label="module.game.fee"
                      sortable={false}
                      textAlign="left"
                      minWidth={100}
                      maxWidth={100}
                    />,
                    <TextField
                      source="pnl"
                      label="module.game.pnl"
                      minWidth={120}
                      maxWidth={120}
                    />,
                  ]
                : [
                    <TextField
                      source="bet"
                      label="module.game.betType"
                      textAlign="left"
                      sortable={false}
                    />,
                    <TextField
                      source="currency"
                      label="module.game.wallet"
                      textAlign="left"
                      sortable={false}
                    />,
                    <FunctionField
                      label="module.game.betAmount"
                      textAlign="left"
                      maxWidth={150}
                      render={(record) => `${formatNumber(record.amount)}`}
                    />,
                    <TextField
                      source="result"
                      label="module.game.result"
                      textAlign="left"
                      sortable={false}
                    />,
                  ]}
            <FunctionField
              source="time"
              label="module.game.time"
              minWidth={180}
              maxWidth={200}
              render={(record) => (record.time ? dayjs(record.time).format(TIME_FORMAT) : '')}
            />
            <FunctionField
              label="module.game.action"
              textAlign="left"
              render={(record) => <BetDetail order={record} gameId={gameId} />}
              minWidth="115px"
            />
          </CGDatagrid>
          {/*<Button*/}
          {/*  style={{*/}
          {/*    position: 'absolute',*/}
          {/*    bottom: -40,*/}
          {/*    left: 0,*/}
          {/*    zIndex: 1,*/}
          {/*    background: '#fff',*/}
          {/*    color: '#000',*/}
          {/*    height: 30,*/}
          {/*    paddingRight: 0,*/}
          {/*    paddingLeft: 10,*/}
          {/*    margin: 0,*/}
          {/*  }}*/}
          {/*  onClick={refresh}*/}
          {/*>*/}
          {/*  <>*/}
          {/*    <HiRefresh style={{ marginRight: 3 }} />*/}
          {/*    {translate('resource.refresh')}*/}
          {/*  </>*/}
          {/*</Button>*/}
        </CgList>
      )}
    </Stack>
  );
};

export default GameHistory;

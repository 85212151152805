import { Box, Dialog, Button as MUIButton } from '@mui/material';
import {
  Filter,
  TextField,
  useListController,
  useTranslate,
  required,
  SelectInput,
  PasswordInput,
  FunctionField,
  minLength,
  email,
  useRecordContext,
  useRedirect,
  useNotify,
} from 'react-admin';
import IconButton from '@mui/material/IconButton';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import CGDatagrid from '../../components/CGDatagrid';
import { CgDateInput } from '../../components/CgDateInput';
import { CgList } from '../../components/CgList';
import {
  CgCreate,
  CgCreateProps,
  CgEdit,
  CgEditProps,
  SelectListAgencyInput,
} from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { DashboardContent } from '../../../../layouts/dashboard';
import CGTextInput from '../../components/CGTextInput';
import { ReactElement, useState } from 'react';
import Stack from '@mui/material/Stack';
import { name } from './index';
import { Iconify } from '../../../../components/iconify';
import dayjs from 'dayjs';
import { Resources } from '../resource';
import CGFilterAgency from '../../components/CGFilterAgency';
import { SelectUserGroupInput } from '../../components/CGInput';
import {useFormContext} from "react-hook-form";

const ListFilter = (props: any) => {
  const { filterValues } = useListController();

  return (
    <Filter {...(props || {})}>
      <FilterGroup alwaysOn spacing={0} p={0}>
        <FilterGroup>
          <CGTextInput source="q" label="module.user.username" />
          <CGFilterAgency source="agencyIds" label="module.user.agency" multiple alwaysOn />
          <SelectUserGroupInput
            source="groupId"
            label="module.user.group"
            multiple={false}
            alwaysOn
          />
          <CgDateInput label="resource.registeredFrom" source="fromTime" sx={{ width: 200 }} />
          <CgDateInput
            label="resource.registeredTo"
            source="toTime"
            sx={{ width: 200 }}
            type="to"
            minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
          />
        </FilterGroup>
      </FilterGroup>
    </Filter>
  );
};

interface IUserFormProps {
  isCreate: boolean;
}
const UserForm = (props: IUserFormProps) => {
  const { isCreate } = props;
  const translate = useTranslate();
  const { watch } = useFormContext()
  const record = useRecordContext()
  const groupId = isCreate ? watch('groupId') : record?.groups?.[0];
  const isShowAgencyField = ['agency', 'agency_operator'].includes(groupId)

  return (
    <Box sx={{ p: 3 }}>
      <Stack spacing={3}>
        <CGTextInput
          source="username"
          label="module.user.username"
          validate={[required()]}
          disabled={!props.isCreate}
        />
        <CGTextInput
          source="email"
          label="module.user.email"
          validate={[required(), email()]}
          disabled={!props.isCreate}
        />

        <SelectUserGroupInput
          source={props.isCreate ? 'groupId' : 'groups'}
          label="module.user.group"
          multiple={false}
          validate={[required()]}
          disabled={!props.isCreate}
        />

        {isShowAgencyField && (
          <SelectListAgencyInput
            source="agencies"
            label="module.user.agency"
            multiple={false}
            validate={[required()]}
            format={(value) => {
              if (Array.isArray(value) && value.length > 0) {
                return value[0]
              }

              return value
            }}
          />
        )}

        <PasswordInput
          source="password"
          label="module.user.password"
          validate={props.isCreate ? [required(), minLength(8)] : [minLength(8)]}
        />
        <SelectInput
          source="isVerified"
          label="module.user.isVerified"
          choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
          validate={[required()]}
        />
      </Stack>
    </Box>
  );
};

const EditPopup = (props: CgEditProps) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const t = useTranslate();

  const onSuccess = () => {
    const currentUrl = window.location.href
    const origin = window.location.origin
    const redirectValue = currentUrl.replace(origin, '')

    notify(t('module.agency.validate.updateSuccess'), { type: 'success', autoHideDuration: 1000 })

    if (props.onClose) {
      props.onClose()
    }
    if (props.resource) {
      redirect(redirectValue, props.resource)
    }
  }

  const onError = (error: any) => {
    const errorCode = error?.body?.code
    const notifyError = (msg: string) => {
      notify(msg, { type: 'error'} )
    }

    switch (errorCode) {
      case 'USER_EXISTED':
      case 'Email or username already exists':
        notifyError(t('module.user.validate.conflict'))
        break
      default:
        notifyError(t('module.agency.validate.unknownError'))
        break
    }
  }

  return (
    <CgEdit
      {...props}
      title="module.user.editUser"
      resource={Resources.users}
      onClose={props.onClose}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess,
        onError,
      }}
      transform={(data) => {
        const agencyData = data?.agencies
        if (!agencyData || typeof agencyData === 'string') {
          return data
        }

        const agencyArr = Array.from(new Set(agencyData ?? []))
        const agencyString: string = agencyArr.toString();
        return {
          ...data,
          agencies: agencyString,
        }
      }}
    >
      <UserForm isCreate={false} />
    </CgEdit>
  )
};

const CreatePopup = (props: CgCreateProps) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const t = useTranslate();

  const onSuccess = () => {
    const currentUrl = window.location.href
    const origin = window.location.origin
    const redirectValue = currentUrl.replace(origin, '')

    notify(t('module.agency.validate.updateSuccess'), { type: 'success', autoHideDuration: 1000 })

    if (props.onClose) {
      props.onClose()
    }
    if (props.resource) {
      redirect(redirectValue, props.resource)
    }
  }

  const onError = (error: any) => {
    const errorCode = error?.body?.code
    const notifyError = (msg: string) => {
      notify(msg, { type: 'error'} )
    }

    switch (errorCode) {
      case 'USER_EXISTED':
      case 'Email or username already exists':
        notifyError(t('module.user.validate.conflict'))
        break
      default:
        notifyError(t('module.agency.validate.unknownError'))
        break
    }
  }

  return (
    <CgCreate
      {...props}
      title="module.user.createUser"
      resource={Resources.users}
      mutationOptions={{
        onSuccess,
        onError,
      }}
      transform={(data) => {
        const agencyData = data?.agencies
        if (!agencyData || typeof agencyData === 'string') {
          return data
        }

        const agencyArr = Array.from(new Set(agencyData ?? []))
        const agencyString: string = agencyArr.toString();
        return {
          ...data,
          agencies: agencyString,
        }
      }}
    >
      <UserForm isCreate />
    </CgCreate>
  )
};

const View = () => {
  const [popup, setPopup] = useState<ReactElement>();
  const translate = useTranslate();

  return (
    <DashboardContent>
      <CustomBreadcrumbs
        heading={translate('module.user.title')}
        action={
          <MUIButton
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setPopup(<CreatePopup onClose={() => setPopup(undefined)} />)}
          >
            {translate('ra.action.create')}
          </MUIButton>
        }
      />

      <CgList resource={name} filters={<ListFilter />}>
        <CGDatagrid
          rowClick={false}
          sx={{
            '.RaBulkActionsToolbar-toolbar': {
              position: 'relative !important',
              transform: 'none !important',
            }
          }}
        >
          <TextField source="username" label="module.user.username" />
          <TextField source="email" label="module.user.email" />
          <TextField source="isVerified" label="module.user.isVerified" minWidth={100} />
          <TextField source="agencyNames" label="module.user.agency" />
          <FunctionField
            label="module.user.group"
            textAlign="left"
            minWidth={150}
            render={(record: any) => {
              return record.groups?.map((group: any) => group.name).join(', ');
            }}
          />
          <FunctionField
            label="module.user.registeredAt"
            textAlign="left"
            minWidth={150}
            render={(record: any) => {
              return dayjs(record.registrationDate).format('YYYY-MM-DD');
            }}
          />

          <FunctionField
            textAlign="left"
            minWidth={90}
            render={(record: any) => (
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() =>
                    setPopup(<EditPopup id={record.id} onClose={() => setPopup(undefined)} />)
                  }
                >
                  <Iconify icon="eva:edit-fill" />
                </IconButton>
              </Stack>
            )}
          />
        </CGDatagrid>
      </CgList>

      <Dialog open={Boolean(popup)} onClose={() => setPopup(undefined)} maxWidth="sm" fullWidth>
        {popup}
      </Dialog>
    </DashboardContent>
  );
};

export default View;

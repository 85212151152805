import { GameIds, TransactionEventEnum } from 'src/configs/game.config';

export const STATUS = {
  Active: 1,
  Locked: 2,
  Deleted: 3,
};

export const STATUS_ENUM = (() => {
  return Object.fromEntries(Object.entries(STATUS).map((a) => a.reverse()));
})();

export const statusOption = [
  { id: STATUS.Active, name: STATUS_ENUM[STATUS.Active] },
  { id: STATUS.Locked, name: STATUS_ENUM[STATUS.Locked] },
  { id: STATUS.Deleted, name: STATUS_ENUM[STATUS.Deleted] },
];

export const countries = [
  { id: 'CN', name: 'China' },
  { id: 'UK', name: 'UnitedKingDom' },
];
export const agencyStatus = (translate: any) => [
  { id: 'ACTIVE', name: translate('resource.active') },
  { id: 'LOCKED', name: translate('resource.lock') },
];
export const gameResultOptions = [
  { id: 'WIN', name: 'Win' },
  { id: 'LOSE', name: 'Lose' },
  { id: 'TIE', name: 'Tie' },
];
export const gameCategoriesOptions = [
  { id: 'HASH', name: 'Hash' },
  { id: 'CRYPTO', name: 'Crypto' },
  { id: 'NFT', name: 'NFT' },
];

export enum EventEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  REWARD = 'REWARD',
  BET = 'BET',
  ENTRY_SELL = 'ENTRY_SELL',
  ENTRY_BUY = 'ENTRY_BUY',
  CLOSE = 'CLOSE',
  TP = 'TP',
  SL = 'SL',
  BURST = 'BURST',
}

export const openTransactionType = [
  { id: 'Buy', name: 'Buy' },
  { id: 'Sell', name: 'Sell' },
];
export const boOpenTransactionType = [
  { id: 'Up', name: 'Up' },
  { id: 'Down', name: 'Down' },
];
export const closeTransactionType = [
  { id: 'CLOSE', name: 'Close' },
  { id: 'TP', name: 'TP' },
  { id: 'SL', name: 'SL' },
  { id: 'BURST', name: 'Burst' },
];

export const transactionTypes = Object.keys(TransactionEventEnum).map((key) => ({
  id: key,
  name: key,
}));

export const transactionSources = [
  {
    id: 'AGENCY',
    name: 'AGENCY',
  },
  {
    id: GameIds.C_BO,
    name: 'C_BO',
  },
  {
    id: GameIds.C_FUTURE,
    name: 'C_FUTURE',
  },
  {
    id: GameIds.H_BS,
    name: 'H_BS',
  },
  {
    id: GameIds.H_LUCKY,
    name: 'H_LUCKY',
  },
  {
    id: GameIds.H_NIUNIU,
    name: 'H_NIUNIU',
  },
  {
    id: GameIds.H_OE,
    name: 'H_OE',
  },
  {
    id: GameIds.H_BP,
    name: 'H_BP',
  },
];

export const txsWalletModes = [
  {
    id: 'transfer',
    name: 'Transfer',
  },
  {
    id: 'seamless',
    name: 'Seamless',
  },
];

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

const convertDateToString = (value: string | Date) => {
  // value is a `Date` object
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateFormatter = (value: string | Date) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value === null || value === '') return '';
  if (value instanceof Date) return convertDateToString(value);
  // Valid dates should not be converted
  if (dateFormatRegex.test(value)) return value;

  return convertDateToString(new Date(value));
};

export const dateParser = (value: string) => {
  //value is a string of "YYYY-MM-DD" format
  const match = dateParseRegex.exec(value);
  if (match === null || match.length === 0) return;
  const d = new Date(parseInt(match[1]), parseInt(match[2], 10) - 1, parseInt(match[3]));
  if (isNaN(d.getDate())) return;
  return d;
};

import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { get, last } from 'lodash';
import {
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  // TextInput,
  useListController,
  useTranslate,
} from 'react-admin';

import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import CGDatagrid from '../../components/CGDatagrid';
import { CgDateInput } from '../../components/CgDateInput';
import { CgList } from '../../components/CgList';
import { SelectListGameInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { ChoiceItem, TabsInput } from '../../components/TabsInput';
import { Resources } from '../resource';
import AccountPanel from './AccountPanel';
import { paths } from '../../../../routes/paths';
import { DashboardContent } from '../../../../layouts/dashboard';
import dayjs from 'dayjs';
import CGTextInput from '../../components/CGTextInput';
import CGFilterAgency from '../../components/CGFilterAgency';
import { formatMoney } from '../../utils/format-number';
import { DATE_FORMAT } from 'src/configs/common.config';
import CopyBtn from 'src/components/button/CopyBtn';
import { formatNumber } from 'src/utils/format-number';

const STATUS_OPTIONS: ChoiceItem[] = [
  { value: '', label: 'resource.all', badgeColor: 'default' },
  { value: 'ACTIVE', label: 'resource.active', badgeColor: 'success' },
  { value: 'LOCK', label: 'resource.lock', badgeColor: 'warning' },
];

function ListFilter(props: any) {
  const { filterValues } = useListController();

  return (
    <Filter {...(props || {})}>
      <FilterGroup alwaysOn spacing={0} p={0}>
        <TabsInput choices={STATUS_OPTIONS} source="status" alwaysOn fullWidth />
        <FilterGroup>
          <CGTextInput label="module.user.search" source="q" />
          <SelectListGameInput source="games" label="module.user.games" />
          <CGFilterAgency source="agencyIds" label="module.user.agency" />
          <CgDateInput label="resource.registeredFrom" source="fromTime" sx={{ width: 200 }} />
          <CgDateInput
            label="resource.registeredTo"
            source="toTime"
            sx={{ width: 200 }}
            type="to"
            minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
          />
        </FilterGroup>
      </FilterGroup>
    </Filter>
  );
}

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.accounts')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.accounts') },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <CgList filters={<ListFilter />}>
        <CGDatagrid rowClick="expand" expand={<AccountPanel />} bulkActionButtons={false}>
          <FunctionField
            label="resource.id"
            textAlign="left"
            minWidth={150}
            maxWidth={150}
            render={(record) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography variant="body2">{record.id}</Typography>
                <CopyBtn text={record.id ?? record.userId} showMsg={false} />
              </Box>
            )}
          />
          <TextField source="name" label="module.user.username" minWidth={151} maxWidth={151} />
          <TextField
            source="agencies.name"
            label="module.user.agency"
            minWidth={108}
            maxWidth={108}
          />
          <TextField source="tags" label="module.user.tags" minWidth={90} maxWidth={90} />
          <FunctionField
            label="module.user.balance"
            textAlign="left"
            width={162}
            minWidth={162}
            maxWidth={162}
            sortable={false}
            render={(record) =>
              formatMoney(record.balance, undefined, { maximumFractionDigits: 3 })
            }
          />

          <FunctionField
            label="module.user.fingerPrint"
            minWidth={312}
            maxWidth={312}
            textAlign="right"
            render={(record) => {
              const history: any = last(record.loginHistories || []);
              if (!history) return <div></div>;

              return (
                <div style={{ maxWidth: 280 }}>
                  <div
                    title={history?.ipAddress}
                    style={{
                      overflowWrap: 'break-word',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {history?.ipAddress}
                    {history?.ipInfo?.countryName ? ` (${history?.ipInfo?.countryName})` : ''}
                  </div>
                  <div
                    style={{
                      color: 'var(--palette-text-disabled)',
                      overflowWrap: 'break-word',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: 12,
                    }}
                    title={history?.userAgent}
                  >
                    {history?.userAgent}
                  </div>
                </div>
              );
            }}
          />
          <FunctionField
            source="createdAt"
            label="module.user.registeredAt"
            minWidth={180}
            maxWidth={200}
            render={(record) =>
              record.createdAt ? dayjs(record.createdAt).format(DATE_FORMAT) : ''
            }
          />
          <ReferenceField
            source="id"
            sortable={false}
            label="module.user.betCount"
            reference={Resources.userStatistics}
            textAlign="right"
          >
            <FunctionField
              label="module.user.betCount"
              style={{ display: 'block', minWidth: 88, maxWidth: 88 }}
              render={(record) => formatNumber(record.betCount)}
            />
          </ReferenceField>
          <ReferenceField
            source="id"
            sortable={false}
            label="module.user.betAmount"
            reference={Resources.userStatistics}
            textAlign="right"
          >
            <FunctionField
              minWidth={110}
              maxWidth={110}
              render={(record) =>
                formatMoney(record.betAmount, undefined, { maximumFractionDigits: 3 })
              }
            />
          </ReferenceField>
          <ReferenceField
            source="id"
            sortable={false}
            label="module.user.winLose"
            reference={Resources.userStatistics}
            textAlign="right"
          >
            <FunctionField
              minWidth={100}
              maxWidth={100}
              render={(record) =>
                <Box sx={{ minWidth: 100, maxWidth: 100 }}>{`${record.win}/${record.lose}`}</Box> ||
                ''
              }
            />
          </ReferenceField>

          <FunctionField
            label="module.user.accStatus"
            align="right"
            minWidth={125}
            maxWidth={125}
            width={125}
            render={(record) => (
              <Box>
                <Chip
                  size="small"
                  variant="soft"
                  color={get({ ACTIVE: 'success', LOCK: 'error' }, `${record.status}`) || 'success'}
                  label={
                    record.status ? translate(`resource.${record.status.toLowerCase()}`) : null
                  }
                />
              </Box>
            )}
          />
        </CGDatagrid>
      </CgList>
    </DashboardContent>
  );
}

export default View;

import { useGetIdentity, useTranslate } from 'react-admin';
import { DonutChart } from '../../game-detail/DonutChart';
import useTxnsByGame from '../hooks/useTxnsByGame';
import { useMemo } from 'react';
import _ from 'lodash';
import { UserModel } from 'src/pages/admin/entities/user.entity';
import { colorByGame } from 'src/configs/color.config';

interface DashboardTxnsByGameChartProps {
  filterValues: any;
  isLoading: boolean;
}

export const DashboardTxnsByGameChart = ({
  filterValues,
  isLoading,
}: DashboardTxnsByGameChartProps) => {
  const translate = useTranslate();

  const { data: userData } = useGetIdentity();
  const user = userData as UserModel;

  const { data: dataTxns } = useTxnsByGame(user, filterValues);

  const dataChartTxns = useMemo(() => {
    if (!_.isEmpty(dataTxns)) {
      const series = dataTxns
        .map((item: any) => ({
          label: `${item?.name ? item?.name : ''} (${
            item?.percentage ? item?.percentage?.toFixed(2) : ''
          }%)`,
          game: item.id,
          value: item?.txns,
        }))
        .sort((a: any, b: any) => b.value - a.value);

      return { series, colors: series.map((item: any) => colorByGame[item.game]) };
    }
    return null;
  }, [dataTxns]);

  if (!dataChartTxns) {
    return <></>;
  }

  return (
    <DonutChart
      title={translate('module.dashBoard.txnsByGames')}
      chart={dataChartTxns}
      // isLoading={isLoading}
    />
  );
};

import { useGetIdentity, useTranslate } from 'react-admin';
import { DonutChart } from '../../game-detail/DonutChart';
import usePlayer from '../hooks/usePlayer';
import { useMemo } from 'react';
import _ from 'lodash';
import { UserModel } from 'src/pages/admin/entities/user.entity';
import { colorByGame } from 'src/configs/color.config';

interface DashboardPlayerChartProps {
  filterValues: any;
  isLoading: boolean;
}

export const DashboardPlayerChart = ({ filterValues, isLoading }: DashboardPlayerChartProps) => {
  const translate = useTranslate();

  const { data: userData } = useGetIdentity();
  const user = userData as UserModel;

  const { data: dataPlayer } = usePlayer(user, filterValues);

  const dataChartPlayer = useMemo(() => {
    if (!_.isEmpty(dataPlayer)) {
      const series = dataPlayer.data
        .map((item: any) => ({
          label: `${item?.name ? item?.name : ''} (${
            item?.percentage ? item?.percentage?.toFixed(2) : ''
          }%)`,
          game: item.id,
          value: item?.numberPlayer,
        }))
        .sort((a: any, b: any) => b.value - a.value);

      return { series, colors: series.map((item: any) => colorByGame[item.game]) };
    }
    return null;
  }, [dataPlayer]);

  if (!dataChartPlayer) {
    return <></>;
  }

  return (
    <DonutChart
      title={
        translate('module.dashBoard.playerByGames') +
        `${dataPlayer?.total ? ` (${dataPlayer?.total} ${translate('module.dashBoard.uniqueUsers')?.toLowerCase() ?? ''})` : ''}`
      }
      chart={dataChartPlayer}
      // loading={isLoading}
    />
  );
};

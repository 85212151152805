import {Box, Button as MUIButton, Dialog, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import get from 'lodash/get';
import {
  Filter,
  FunctionField,
  TextField,
  useListController,
  useTranslate,
  required,
  maxLength,
  regex,
  SelectInput,
  useRecordContext,
  useGetList,
  useRedirect,
  useNotify,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import CGDatagrid from '../../components/CGDatagrid';
import { CgDateInput } from '../../components/CgDateInput';
import { CgList } from '../../components/CgList';
import {
  CgCreate,
  CgCreateProps,
  CgEdit,
  CgEditProps,
  CgRecordButton,
  SelectListCurrencyInput
} from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { ChoiceItem, TabsInput } from '../../components/TabsInput';
import { paths } from '../../../../routes/paths';
import { DashboardContent } from '../../../../layouts/dashboard';
import dayjs from 'dayjs';
import CGTextInput from '../../components/CGTextInput';
import { DATE_FORMAT } from 'src/configs/common.config';
import CopyBtn from 'src/components/button/CopyBtn';
import {Iconify} from "../../../../components/iconify";
import React, {ReactElement, useEffect, useState} from "react";
import {Image} from "../../../../components/image";
import {f} from "../../../../utils/format";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {name} from "./index";
import { useRouter } from 'src/routes/hooks';
import {Resources} from "../resource";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const STATUS_OPTIONS: ChoiceItem[] = [
  { value: '', label: 'resource.all', badgeColor: 'default' },
  { value: 'ACTIVE', label: 'resource.active', badgeColor: 'success' },
  { value: 'LOCK', label: 'resource.lock', badgeColor: 'warning' },
];

function ListFilter(props: any) {
  const { filterValues } = useListController();

  return (
    <Filter {...(props || {})}>
      <FilterGroup alwaysOn spacing={0} p={0}>
        <TabsInput
          choices={STATUS_OPTIONS}
          source="status"
          alwaysOn
          fullWidth
        />
        <FilterGroup>
          <CGTextInput
            label="module.agency.filter.search"
            source="q"
          />
          <SelectListCurrencyInput
            source="currencyCodes"
            label="module.agency.filter.wallets"
          />
          <CgDateInput
            label="module.agency.filter.registeredFrom"
            source="fromTime"
            sx={{ width: 200 }}
          />
          <CgDateInput
            label="module.agency.filter.registeredTo"
            source="toTime"
            sx={{ width: 200 }}
            type="to"
            minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
          />
        </FilterGroup>
      </FilterGroup>
    </Filter>
  );
}

function View() {
  const [popup, setPopup] = useState<ReactElement>();
  const translate = useTranslate();
  const router = useRouter()

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.agencies')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.agencies') },
        ]}
        action={
          <MUIButton
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={
              () => setPopup(
                <CreatePopup
                  onClose={() => setPopup(undefined)}
                />
              )
            }
          >
            {translate('module.agency.newAgency')}
          </MUIButton>
        }
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <CgList filters={<ListFilter />}>
        <CGDatagrid rowClick={false} bulkActionButtons={false}>
          <FunctionField
            label="module.agency.table.id"
            textAlign="left"
            minWidth="200px"
            maxWidth="200px"
            render={(record) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography variant="body2">{record.id}</Typography>
                <CopyBtn text={record.id ?? record.userId} showMsg={false} />
              </Box>
            )}
          />
          <FunctionField
            label="module.agency.table.logoUrl"
            textAlign="left"
            minWidth="200px"
            maxWidth="200px"
            render={(record) => {
              const logoUrl = record?.logoUrl
              if (!logoUrl) return <></>

              return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Image src={record?.logoUrl} sx={{ maxHeight: '200px', maxWidth: '100%' }} />
                </Box>
              )
            }}
          />
          <TextField
            source="name"
            label="module.agency.table.name"
            minWidth="300px"
            maxWidth="300px"
          />

          {/* apikey column */}
          {/*<FunctionField*/}
          {/*  source="apikey"*/}
          {/*  label="module.agency.table.apikey"*/}
          {/*  sortable={false}*/}
          {/*  width="200px"*/}
          {/*  minWidth="200px"*/}
          {/*  maxWidth="200px"*/}
          {/*  textAlign="left"*/}
          {/*  render={(record) => {*/}
          {/*    return (*/}
          {/*      <Box*/}
          {/*        sx={{*/}
          {/*          whiteSpace: 'normal',*/}
          {/*          wordBreak: 'break-word',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {record?.apikey}*/}
          {/*      </Box>*/}
          {/*    )*/}
          {/*  }}*/}
          {/*/>*/}

          {/* secret key column */}
          {/*<FunctionField*/}
          {/*  source="secret"*/}
          {/*  label="module.agency.table.secret"*/}
          {/*  sortable={false}*/}
          {/*  width="200px"*/}
          {/*  minWidth="200px"*/}
          {/*  maxWidth="200px"*/}
          {/*  textAlign="left"*/}
          {/*  render={(record) => {*/}
          {/*    return (*/}
          {/*      <Box*/}
          {/*        sx={{*/}
          {/*          whiteSpace: 'normal',*/}
          {/*          wordBreak: 'break-word',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {record?.secret}*/}
          {/*      </Box>*/}
          {/*    )*/}
          {/*  }}*/}
          {/*/>*/}

          {/* whitelistedIps column */}
          {/*<FunctionField*/}
          {/*  source="whitelistedIps"*/}
          {/*  label="module.agency.table.whitelistedIps"*/}
          {/*  sortable={false}*/}
          {/*  width="200px"*/}
          {/*  minWidth="200px"*/}
          {/*  maxWidth="200px"*/}
          {/*  textAlign="left"*/}
          {/*  render={(record) => {*/}
          {/*    const whitelistedIps = record?.whitelistedIps*/}
          {/*    if (!whitelistedIps) return <></>*/}

          {/*    if (typeof whitelistedIps === 'string') {*/}
          {/*      return whitelistedIps?.split(',')?.map((ip: string) => <div>{ip}</div>)*/}
          {/*    }*/}

          {/*    return whitelistedIps?.map((ip: string) => <div>{ip}</div>)*/}
          {/*  }}*/}
          {/*/>*/}

          <FunctionField
            source="walletMode"
            label="module.agency.table.walletMode"
            minWidth="150px"
            maxWidth="150px"
            render={(record) => translate(`module.agency.walletMode.${record.walletMode}`)}
          />
          <FunctionField
            source="currency"
            label="module.agency.table.wallet"
            sortable={false}
            width="100px"
            minWidth="100px"
            maxWidth="100px"
            textAlign="left"
            render={(record) => {
              const currency = record?.currency
              if (!currency) return <></>

              if (typeof currency === 'string') {
                return record?.currency?.split(',')?.map((currency: string) => <div>{currency}</div>)
              }

              return record?.currency?.map((currency: string) => <div>{currency}</div>)
            }}
          />
          <FunctionField
            source="creditLimit"
            label="module.agency.table.creditLimit"
            minWidth="200px"
            maxWidth="200px"
            render={(record) => f(record?.creditLimit)}
          />
          <FunctionField
            source="creditFrozen"
            label="module.agency.table.creditFrozen"
            minWidth="200px"
            maxWidth="200px"
            render={(record) => f(record?.creditFrozen)}
          />
          <FunctionField
            source="createdAt"
            label="module.agency.table.registeredAt"
            minWidth={180}
            maxWidth={200}
            render={(record) =>
              record.createdAt ? dayjs(record.createdAt).format(DATE_FORMAT) : ''
            }
          />

          {/* activatedAt column */}
          {/*<FunctionField*/}
          {/*  source="activatedAt"*/}
          {/*  label="module.agency.table.activatedAt"*/}
          {/*  minWidth={180}*/}
          {/*  maxWidth={200}*/}
          {/*  render={(record) =>*/}
          {/*    record.activatedAt ? dayjs(record.activatedAt).format(DATE_FORMAT) : ''*/}
          {/*  }*/}
          {/*/>*/}
          <FunctionField
            label="module.agency.table.status"
            align="left"
            textAlign="left"
            minWidth="90px"
            maxWidth="90px"
            width="90px"
            render={(record) => (
              <Box>
                <Chip
                  size="small"
                  variant="soft"
                  color={get({ ACTIVE: 'success', LOCK: 'error' }, `${record.status}`) || 'success'}
                  label={
                    record.status ? translate(`resource.${record.status.toLowerCase()}`) : null
                  }
                />
              </Box>
            )}
          />
          <FunctionField
            render={(record) => {
              return (
                <Stack direction="row" alignItems="center">
                  <Tooltip
                    title={translate('module.agency.table.quickActions.edit')}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <CgRecordButton
                        component={IconButton as any}
                        onClick={() => {}}
                        onRowClick={
                          (record) =>
                            setPopup(<EditPopup id={record?.id} onClose={
                              () => setPopup(undefined)
                            } />)
                        }
                        sx={{
                          width: '36px',
                          height: '36px',
                          minWidth: 'auto',
                          borderRadius: '50px',
                          '.MuiButton-icon': {
                            margin: '0',
                          },
                        }}
                      >
                        <Iconify icon="solar:pen-bold" />
                      </CgRecordButton>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={translate('module.agency.table.quickActions.lock')}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      // onClick={() => {
                      //   confirm.onTrue();
                      //   popover.onClose();
                      // }}
                      sx={{
                        color: 'warning.main',
                        '&.Mui-disabled': {
                          cursor: 'not-allowed',
                          pointerEvents: 'auto',
                        },
                      }}
                      disabled
                    >
                      <Iconify icon="solar:lock-bold" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={translate('module.agency.table.quickActions.delete')}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      // onClick={() => {
                      //   confirm.onTrue();
                      //   popover.onClose();
                      // }}
                      sx={{
                        color: 'error.main',
                        '&.Mui-disabled': {
                          cursor: 'not-allowed',
                          pointerEvents: 'auto',
                        },
                      }}
                      disabled
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )
            }}
          />
        </CGDatagrid>
      </CgList>
      <Dialog
        fullWidth
        maxWidth={false}
        open={!!popup}
        onClose={() => setPopup(undefined)}
        PaperProps={{ sx: { maxWidth: 720 } }}
      >
        {popup}
      </Dialog>
    </DashboardContent>
  );
}

function SelectAgencyWalletsInput({label}: {label: string}) {
  const translate = useTranslate()
  const record = useRecordContext()
  const { data: allChoices } = useGetList(Resources.currency);
  const { formState, register } = useFormContext()
  const { errors } = formState
  const isError = !!errors?.currency?.message
  const recordCurrency = record?.currency?.split(',')

  return (
    <FormControl fullWidth>
      <InputLabel
        id="currency-label"
        sx={{
          color: isError ? 'var(--palette-error-main) !important' : ''
        }}
      >
        {label} *
      </InputLabel>
      <Select
        multiple
        labelId="currency-label"
        label={`${label} *`}
        defaultValue={recordCurrency ?? []}
        sx={{
          width: '100%',
          '.MuiOutlinedInput-notchedOutline': isError ? {
            borderColor: 'var(--palette-error-main) !important',
          } : null
        }}
        {...register("currency", {
          validate: (value: string) => {
            const isValid = !!value && (value.length > 0)
            return isValid || translate('module.agency.validate.walletRequired')
          },
        })}
      >
        {allChoices?.map((choice) => {
          return (
            <MenuItem value={choice.code}>{choice.name}</MenuItem>
          )
        })}
      </Select>
      {errors?.currency?.message && (
        <Box
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained"
          sx={{
            color: 'var(--palette-error-main)',
            height: 'auto',
            minHeight: 'auto',
            margin: '0',
            fontSize: '0.75rem',
            lineHeight: '1.5',
            fontWeight: '400',
          }}
        >
          {`${errors?.currency?.message}`}
        </Box>
      )}
    </FormControl>
  )
}

function AgencyForm({isEdit}: {isEdit?: boolean}) {
  const translate = useTranslate();

  const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
  const whitelistedIpsRegex = /^((?:(?:\d{1,3}\.){3}\d{1,3})|(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}|::(?:[a-fA-F0-9]{1,4}:){0,5}(?:[a-fA-F0-9]{1,4}|:)|(?:[a-fA-F0-9]{1,4}:){1,7}:|(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}|(\*))(\s*,\s*((?:(?:\d{1,3}\.){3}\d{1,3})|(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}|::(?:[a-fA-F0-9]{1,4}:){0,5}(?:[a-fA-F0-9]{1,4}|:)|(?:[a-fA-F0-9]{1,4}:){1,7}:|(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}|(\*)))*$/; // ipv4, ipv6 or *
  const creditRegex = /^(?=.)([+-]?([0-9]*)(\.([0-9]{1,2}))?)$/;

  const validateName = [
    required(translate('module.agency.validate.nameRequired')),
    maxLength(255, translate('module.agency.validate.nameMaxLength')),
  ];
  const validateApiKey = [
    required(translate('module.agency.validate.apiKeyRequired')),
    maxLength(100, translate('module.agency.validate.apiKeyMaxLength')),
  ]
  const validateSecretKey = [
    required(translate('module.agency.validate.secretKeyRequired')),
    maxLength(100, translate('module.agency.validate.secretKeyMaxLength')),
  ]
  const validateBaseApiUrl = [
    required(translate('module.agency.validate.baseApiUrlRequired')),
    regex(urlRegex, translate('module.agency.validate.notValidUrl')),
  ]
  const validateWhitelistedIps = [
    required(translate('module.agency.validate.whiteListedIPsRequired')),
    regex(whitelistedIpsRegex, translate('module.agency.validate.whiteListedIPsNotValid')),
  ]
  const validateLogoUrl = [
    regex(urlRegex, translate('module.agency.validate.notValidUrl')),
  ]
  const validateCreditLimit = [
    required(translate('module.agency.validate.transferLimitRequired')),
    regex(creditRegex, translate('module.agency.validate.transferLimitNotValid')),
  ]
  const validateCreditFrozen = [
    required(translate('module.agency.validate.creditFrozenRequired')),
    regex(creditRegex, translate('module.agency.validate.creditFrozenNotValid')),
  ]
  const validateStatus = [
    required(translate('module.agency.validate.statusRequired')),
  ]
  const validateWalletMode = [
    required(translate('module.agency.validate.walletModeRequired')),
  ]

  return (
    <Box
      mt={'10px'}
      rowGap="15px"
      columnGap="10px"
      display="grid"
      alignItems="baseline"
      gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
      sx={{
        '.MuiFormHelperText-root': {
          height: 'auto',
          minHeight: 'auto',
          margin: '0',
          '> span': {
            display: 'none',
          },
        },
        '.ra-input': {
          // width: 'calc(50% - 5px)',
          // maxWidth: 'calc(50% - 5px)',
          // flex: '1',
          // marginBottom: '10px',
        },
      }}
    >
      <CGTextInput
        source="name"
        label="module.agency.table.name"
        validate={validateName}
      />
      <CGTextInput
        source="apikey"
        label="module.agency.table.apikey"
        validate={validateApiKey}
      />
      <CGTextInput
        source="secret"
        label="module.agency.table.secret"
        validate={validateSecretKey}
      />
      <CGTextInput
        source="baseApiUrl"
        label="module.agency.baseApiUrl"
        validate={validateBaseApiUrl}
      />
      <Stack>
        <CGTextInput
          source="whitelistedIps"
          label="module.agency.table.whitelistedIps"
          validate={validateWhitelistedIps}
        />
        <Typography
          sx={{
            fontSize: '13px',
            color: 'var(--palette-text-secondary)',
            marginTop: '5px',
            paddingLeft: '14px',
          }}
        >
          {translate('module.agency.whiteListedIpsDesc')}
        </Typography>
      </Stack>
      <Stack>
        <CGTextInput
          source="logoUrl"
          label="module.agency.logoUrl"
          validate={validateLogoUrl}
        />
        <Typography
          sx={{
            fontSize: '13px',
            color: 'var(--palette-text-secondary)',
            marginTop: '5px',
            paddingLeft: '14px',
          }}
        >
          {translate('module.agency.logoUrlDesc')}
        </Typography>
      </Stack>
      <SelectInput
        source="walletMode"
        label="module.agency.table.walletMode"
        validate={validateWalletMode}
        disabled={isEdit}
        choices={[
          { id: 'transfer', name: translate('module.agency.walletMode.transfer') },
          { id: 'seamless', name: translate('module.agency.walletMode.seamless') },
        ]}
      />
      <SelectAgencyWalletsInput
        label={translate('module.agency.filter.wallets')}
      />
      <CGTextInput
        source="creditLimit"
        label="module.agency.table.creditLimit"
        validate={validateCreditLimit}
      />
      <CGTextInput
        source="creditFrozen"
        label="module.agency.table.creditFrozen"
        validate={validateCreditFrozen}
      />
      <SelectInput
        source="status"
        label="module.agency.table.status"
        validate={validateStatus}
        choices={[
          { id: 'ACTIVE', name: translate('resource.active') },
          { id: 'LOCK', name: translate('resource.lock'), disabled: true },
        ]}
        defaultValue="ACTIVE"
      />
    </Box>
  );
}

function EditPopup(props: CgEditProps) {
  const redirect = useRedirect();
  const notify = useNotify();
  const t = useTranslate();

  const onSuccess = () => {
    const currentUrl = window.location.href
    const origin = window.location.origin
    const redirectValue = currentUrl.replace(origin, '')

    notify(t('module.agency.validate.updateSuccess'), { type: 'success', autoHideDuration: 1000 })

    setTimeout(() => {
      if (props.onClose) {
        props.onClose()
      }
      if (props.resource) {
        redirect(redirectValue, props.resource)
      }
    }, 500)
  }

  const onError = (error: any) => {
    const errorCode = error?.body?.code
    const notifyError = (msg: string) => {
      notify(msg, { type: 'error'} )
    }

    switch (errorCode) {
      case 'Conflict':
        notifyError(t('module.agency.validate.conflict'))
        break
      default:
        notifyError(t('module.agency.validate.unknownError'))
        break
    }
  }

  return (
    <CgEdit
      resource={name}
      title="module.agency.edit.editAgency"
      {...props}
      onClose={props.onClose}
      actions={false}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess,
        onError,
      }}
      transform={(data) => {
        const currencyData = data?.currency
        if (!currencyData || typeof currencyData === 'string') {
          return data
        }

        const currencyArr = Array.from(new Set(currencyData ?? []))
        const currencyString: string = currencyArr.toString();
        return {
          ...data,
          currency: currencyString,
        }
      }}
    >
      <AgencyForm isEdit />
    </CgEdit>
  );
}

function CreatePopup(props: CgCreateProps) {
  const redirect = useRedirect();
  const notify = useNotify();
  const t = useTranslate();

  const onSuccess = () => {
    const currentUrl = window.location.href
    const origin = window.location.origin
    const redirectValue = currentUrl.replace(origin, '')

    notify(t('module.agency.validate.updateSuccess'), { type: 'success', autoHideDuration: 1000 })

    setTimeout(() => {
      if (props.onClose) {
        props.onClose()
      }
      if (props.resource) {
        redirect(redirectValue, props.resource)
      }
    }, 500)
  }

  const onError = (error: any) => {
    const errorCode = error?.body?.code
    const notifyError = (msg: string) => {
      notify(msg, { type: 'error'} )
    }

    switch (errorCode) {
      case 'Conflict':
        notifyError(t('module.agency.validate.conflict'))
        break
      default:
        notifyError(t('module.agency.validate.unknownError'))
        break
    }
  }

  return (
    <CgCreate
      resource={name}
      title="module.agency.newAgency"
      {...props}
      onClose={props.onClose}
      mutationOptions={{
        onSuccess,
        onError,
      }}
      transform={(data) => {
        const currencyData = data?.currency
        if (!currencyData || typeof currencyData === 'string') {
          return data
        }

        const currencyArr = Array.from(new Set(currencyData ?? []))
        const currencyString: string = currencyArr.toString();
        return {
          ...data,
          currency: currencyString,
        }
      }}
    >
      <AgencyForm />
    </CgCreate>
  );
}

export default View;
